import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import CONFIG from "../../config.json";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../services/urlServiceConnections";
import GetEnvironment  from "../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";

function LoadMoreAssetsLive() {
  const [post, setPost] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)
  const [index, setIndex] = useState(3) 
  const [search,  setSearch] = useState(false)
  const [isSearchValid,  setIsSearchValid] = useState(true)
  const [siteSearch,  setSiteSearch] = useState('')
  const [siteSearchIndex,  setSiteSearchIndex] = useState('')

  // const [mainimage,  setMainImage] = useState('')
  // const [opt1image,  setOpt1Image] = useState('')
  // const [opt2image,  setOpt2Image] = useState('')
  // const [opt3image,  setOpt3Image] = useState('')
  // const [video,  setVideo] = useState('')

  const initialPosts = slice(post, 0, index)
  
 // function getApiPath () {     
  //   return GetUrl("retrieveAssetcommodities");   
  // }

  // function getApiPathForSearch () {     
  //   return GetUrl("searchcommodities");   
  // }

  function getApiPathWithExtensionPerRegion() {     
    return GetUrl("retrieveAssetWithExtensionByRegion");   
  }

  
  const extension = window.location.href.split('?')[1];

  const getData = () => {  
    if(extension){
      const url =  getApiPathWithExtensionPerRegion();
      axios.post(url, {         
        redirectExtension: extension           
           })
           .then(res => {
            if(res.data !=="no data") 
            {
              setPost(res.data);
            }
           
          })
          .catch((e) => console.log(e)) 
        }
      }


  const loadMore = () => {
    setIndex(index + 1)
    console.log(index)
    if(index >= post.length){
      setIsCompleted(true)      
    } else {
      setIsCompleted(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const env = GetEnvironment();
  
  // const  startSearch = (e) =>{   
  //   const url = getApiPathForSearch();
  //   if(siteSearch=="")
  //   {
  //     setIndex(0);
  //     setIsSearchValid(false);
  //     return
  //   }
  //   axios.post(url, {         
  //      siteSearch: siteSearch           
  //         })
  //         .then(res => {  
  //          setPost(res.data);
  //          setSearch(true); 
  //          setSiteSearch('');
  //      })
  //      .catch((e) => console.log(e))    
  // }
  // const mainimage = require(`../../serverMerchandise/assets/${this.props.obj.PathMainImage}`).default;
  return (
    <div>
      {/* <row>
        <div class="col-md4 col-offset-0"><h2 className="mb-3">Best African agro and casual commodities service.</h2></div>
        <div class="col-md3 col-offset-2"><label for="site-search">Start searching on the site:</label>
        <input type="search"   value={siteSearch} id="siteSearch"
          onChange={event => setSiteSearch(event.target.value)}
         ></input>
        <button className="home-search-button" onClick ={startSearch}>Search</button>
        </div>
      </row> */}
      <p></p>
      {initialPosts.map((item) => {
       
        ////live 
        const mainimage = `/${item.PathMainImage}`;
        const opt1image = `/${item.PathFirstOptionalImage}`;
        const opt2image = `/${item.PathSecondOptionalImage}`;
        const opt3image = `/${item.PathThirdOptionalImage}`;
        const video = `/${item?.Videopath}`??'';

         /// end live
        
        const assetNote = item?.Sellernote;
        const assetDescription = item?.Description?? 'Commodity trading shop';
        const assetName = item?.Name;
        const assetPrice = item?.Price;
        const reply = item?.Sellernote;
        const replyerName = item?.ShopOwnerTitle +' '+item?.ShopOwnerName+' '+item?.ShopOwnerSurname;
        const dateAvailable =item?.InsertionDate?? new Date();
        const formattedDate = dateAvailable.slice(0, 10);//dateAvailable.split(' ')[0];
        const location = item?.Address;
        const contact = item?.SellerPhone? ' Your Business hub phone number: ' + item?.SellerPhone :'';
        const productId = item?.productId;
        const productName = item?.productName;
        const serviceModel = item?.ServiceModel;
        const deliveryModel = item?.DeliveryModel;
        const hasMenue = item?.MenueStatus;
        const sellerEmail = item?.SellerEmail;
        const sellerPhone = item?.SellerPhone;
        const shopName =item?.ShopName;

      
        LocalStorageService("set", "sellerPhoneNumber", item?.SellerPhone);
        LocalStorageService("set", "sellerEmail", item?.SellerEmail);
        LocalStorageService("set", "shopName", item?.ShopName);
        LocalStorageService("set","hasMenue", item?.HasMenue);
        LocalStorageService("remove","productId"); 

        LocalStorageService("set","mainimage", mainimage);
        LocalStorageService("set","opt1image", opt1image);
        LocalStorageService("set","opt2image", opt2image);
        LocalStorageService("set","opt3image", opt3image);
        LocalStorageService("set","video", video);
        LocalStorageService("set","assetNote", assetNote);
        LocalStorageService("set","assetDescription", assetDescription);
        LocalStorageService("set","assetName", assetName);
        LocalStorageService("set","assetPrice", assetPrice);
        LocalStorageService("set","reply", reply);
        LocalStorageService("set","replyerName", replyerName);   
        LocalStorageService("set","prodName", productName);
      
       
        const serviceModelReworked = () =>{        
          switch(item.Size){
            case 'canMicroEvent':
              return 'Can supply products for event of less than 50 people'
              case 'canMiniEvent':
                return 'Can supply products for event of 50 to 100 people'
              case 'canSmallEvent':
                return 'Can supply products for event of 100 to 200 people'
              case 'canMediumEvent':
                return 'Can supply products for event of 200 to 350 people'
              case 'canBigEvent':
                return 'Can supply products for event of 350 to 500 people'
              case 'canLargeEvent':
                return 'Can supply products for event of 500 and more people'
                case 'cannotEvent':
                  return 'No cannot supply if big order'                
          default:
          return 'Not applicable';
          }
        }
      
        const deliveryModelReworked = () =>{        
          switch(item.ContractType){
          case 'HomeService':
          return 'I deliver product at your home or by appointment'
          case 'InMyShop':
            return 'I possess a shop'
            case 'Other':
              return 'Other'                   
          default:
          return 'Not applicable';
          }
        
        } 
       // const productName = item?.productName;
        return (
          <div>         
          <div
            className="lpCommodities"
            key={item.Id}
          >
                            
              <div className="lpCommodities-Content"><h3>{assetDescription}.</h3></div>
              <div className="lpCommodities-Content"><h3>Posted since {formattedDate}.</h3></div>
              <div className="lpCommodities-Content"><h3> Available here:  {location}.</h3></div>
              <div className="lpCommodities-Content"><h4>Service: {serviceModelReworked()}.</h4></div>
              <div className="lpCommodities-Content"><h4>Delivery: {deliveryModelReworked()}.</h4></div>
              <div>               
                <Link to={{
                   pathname: `/productdetails/bm=${post[0].BusinessModel}?${productId}`, 
                  state: {
                    mainimage, opt1image, opt2image, opt3image,
                    assetNote, assetDescription, assetName, assetPrice,
                    reply, replyerName, video, productId, productName, 
                    serviceModel, deliveryModel,  hasMenue,
                    sellerEmail, sellerPhone, shopName
                  }
            }}>
               <Row>    
               <Col md={{ span: 4, offset: 4 }}>
              <div className ="home-page-image"> 
              <img src={mainimage}  width="100%" height="100%" />                           
              </div>
              </Col>
              </Row>
               <div className="lpCommodities-link-dlp"> <h4>{contact}. Click on this link for more details. </h4></div>
            </Link>
            </div>
            </div>           
            </div>          
        )
      })}

{isSearchValid && ( <div className="d-grid mt-3 mb-5">
        {isCompleted ? (
          <button
            onClick={loadMore}
            type="button"
            className="btn btn-danger disabled"
          >
            There is no more commodity.
          </button>
        ) : (
          <button onClick={loadMore} type="button" className="btn btn-danger">
            More commodities.
          </button>
        )}
      </div>
      )}
    </div>
  )
}
export default LoadMoreAssetsLive


