import {useState, useEffect} from 'react';
import config from '../seller_config.json';
import GetEnvironment from './getEnvironment';


function baseUrl(){   
    switch(GetEnvironment()){
      case "dev":
      return config.TEST_BASE ;//"groupakwabatech.com",      
      case "live":
      return  config.BASE_URL_LOCAL ;
      case "test":
      return config.BASE_URL_LIVE ;      
    }
    }
 
 function getContactUsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.MANUAL_URL.CONTACTUS;
        case "live":
        return  config.DIRECT_LIVE.CONTACTUS;
        case "test":
        return  config.DIRECT_TEST.CONTACTUS;
        }
}

function getBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.MANUAL_URL.BOOKINGS;
        case "live":
        return  config.DIRECT_LIVE.BOOKINGS;
        case "test":
        return  config.DIRECT_TEST.BOOKINGS;
        }
    }

 function retrieveMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEMYMESSAGES;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEMYMESSAGES;
        case "test":
        return config.DIRECT_TEST.RETRIEVEMYMESSAGES;
        }

}
 function getAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.APPOINTMENTS;
        case "live":
        return config.DIRECT_LIVE.APPOINTMENTS;
        case "test":
        return config.DIRECT_TEST.APPOINTMENTS;
        }
    
}
 function retrieveBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEBOOKINGS;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEBOOKINGS;
        case "test":
        return config.DIRECT_TEST.RETRIEVEBOOKINGS;
        }

}


function retrieveInterestsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEINTERESTS;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEINTERESTS;
        case "test":
        return config.DIRECT_TEST.RETRIEVEINTERESTS;
        }

}


function retrieveAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEAPPOINTMENTS;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEAPPOINTMENTS;
        case "test":
        return config.DIRECT_TEST.RETRIEVEAPPOINTMENTS;
        }

}

function retrievePaymentByInvoiceIdUrl(){
    switch(GetEnvironment()){
        case "dev":
            return config.MANUAL_URL.PAYMENT.RetrievePaymentValueByTicketId; 
            case "live":
            return config.DIRECT_LIVE.PAYMENT.RetrievePaymentValueByTicketId; 
            case "test":
            return config.DIRECT_TEST.PAYMENT.RetrievePaymentValueByTicketId;
    }}

function getPaymentCardUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.PAYMENT.RetrievePaymentDetails; 
        case "live":
        return config.DIRECT_LIVE.PAYMENT.RetrievePaymentDetails; 
        case "test":
        return config.DIRECT_TEST.PAYMENT.RetrievePaymentDetails;
        }        
}


 function registerSimpleUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.REGISTERSIMPLE;
        case "live": 
        return config.DIRECT_LIVE.REGISTERSIMPLE; 
        case "test":  
        return config.DIRECT_TEST.REGISTERSIMPLE; 
        }      

}

function resetPasswordUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RESETPASSWORD;
        case "live": 
        return config.DIRECT_LIVE.RESETPASSWORD; 
        case "test":  
        return config.DIRECT_TEST.RESETPASSWORD; 
        }      

}


 function loginUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.LOGIN;
        case "live": 
        return config.DIRECT_LIVE.LOGIN;
        case "test":
        return config.DIRECT_TEST.LOGIN;
    }

}
 function loginManagerUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.LOGINMANAGER;
        case "live":
        return config.DIRECT_LIVE.LOGINMANAGER;
        case "test":
        return config.DIRECT_TEST.LOGINMANAGER;
        }
    
}
 function logoutUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.MANUAL_URL.LOGOUT;
        case "live":
        return  config.DIRECT_LIVE.LOGOUT;
        case "test":
        return  config.DIRECT_TEST.LOGOUT;
        }
    
}
 function insertAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.INSERTASSET;
        case "live":
        return config.DIRECT_LIVE.INSERTASSET;
        case "test":
        return config.DIRECT_TEST.INSERTASSET;
        }
}

function retrieveAssetWithextensionUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEASSETWITHEXTENSION;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEASSETWITHEXTENSION;
        case "test":
        return config.DIRECT_TEST.RETRIEVEASSETWITHEXTENSION;
        }
}

 function retrieveAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEASSET;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEASSET;
        case "test":
        return config.DIRECT_TEST.RETRIEVEASSET;
        }
}
 function displayAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.DISPLAYASSET;    
        case "live":
        return config.DIRECT_LIVE.DISPLAYASSET;    
        case "test":
        return config.DIRECT_TEST.DISPLAYASSET; 
        }

}

 function uploadVideoUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.UPLOADMkDirVideo; 
        case "live":
        return config.DIRECT_LIVE.UPLOADMkDirVideo; 
        case "test":
        return config.DIRECT_TEST.UPLOADMkDirVideo;
        } 
}

function searchUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.SEARCH; 
        case "live":
        return config.DIRECT_LIVE.SEARCH; 
        case "test":
        return config.DIRECT_TEST.SEARCH;
        } 
}

function generateInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GENERATEINVOICE; 
        case "live":
        return config.DIRECT_LIVE.GENERATEINVOICE; 
        case "test":
        return config.DIRECT_TEST.GENERATEINVOICE;
        }        
}

function getInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETINVOICE; 
        case "live":
        return config.DIRECT_LIVE.GETINVOICE; 
        case "test":
        return config.DIRECT_TEST.GETINVOICE;
        }        
}

function getInvoiceByNumberUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETINVOICEBYNUMBER; 
        case "live":
        return config.DIRECT_LIVE.GETINVOICEBYNUMBER; 
        case "test":
        return config.DIRECT_TEST.GETINVOICEBYNUMBER;
        }        
}



function getAssetDetailByIdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETASSETDETAILSBYID; 
        case "live":
        return config.DIRECT_LIVE.GETASSETDETAILSBYID; 
        case "test":
        return config.DIRECT_TEST.GETASSETDETAILSBYID;
        }        
}



function sendPaymentConfirmationdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.SENDPAYMENTCONFIRMATION; 
        case "live":
        return config.DIRECT_LIVE.SENDPAYMENTCONFIRMATION; 
        case "test":
        return config.DIRECT_TEST.SENDPAYMENTCONFIRMATION;
        }        
}



function generateOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GENERATEORDER; 
        case "live":
        return config.DIRECT_LIVE.GENERATEORDER; 
        case "test":
        return config.DIRECT_TEST.GENERATEORDER;
        }        
}

function getOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETORDER; 
        case "live":
        return config.DIRECT_LIVE.GETORDER; 
        case "test":
        return config.DIRECT_TEST.GETORDER;
        }        
}


function generateFoodMenueUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GENERATEFOODMENUE; 
        case "live":
        return config.DIRECT_LIVE.GENERATEFOODMENUE; 
        case "test":
        return config.DIRECT_TEST.GENERATEFOODMENUE;
        }        
}

function getFoodMenueAsAChefUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GENERATEFOODMENUEASACHEF; 
        case "live":
        return config.DIRECT_LIVE.GENERATEFOODMENUEASACHEF; 
        case "test":
        return config.DIRECT_TEST.GENERATEFOODMENUEASACHEF;
        }        
}


function getFoodMenueUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETFOODMENUE; 
        case "live":
        return config.DIRECT_LIVE.GETFOODMENUE; 
        case "test":
        return config.DIRECT_TEST.GETFOODMENUE;
        }        
}


function uploadPicsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.UPLOADMkDirPics; 
        case "live":
        return config.DIRECT_LIVE.UPLOADMkDirPics; 
        case "test":
        return config.DIRECT_TEST.UPLOADMkDirPics;
        } 
}


function retrieveBuyerMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEBUYERMESSAGES;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEBUYERMESSAGES;
        case "test":
        return config.DIRECT_TEST.RETRIEVEBUYERMESSAGES;
        }
}


export default function GetUrl(targetUrl){
    switch(targetUrl){
        case "contactUsseller":
        return getContactUsUrl(); 

        case "retrieveMessagesseller":
            return retrieveMessagesUrl(); 
    
        case "retrieveBuyerMessagesseller":
            return retrieveBuyerMessagesUrl(); 

        case "bookingsseller":
        return getBookingsUrl(); 

        case "appointmentsseller":
            return getAppointmentsUrl(); 
        
        case "retrieveBookingsseller":
            return retrieveBookingsUrl(); 

        case "retrieveInterestsseller":
            return retrieveInterestsUrl(); 

        case "retrieveAppointmentsseller":
            return retrieveAppointmentsUrl(); 

        case "registerSimpleseller":
        return registerSimpleUrl();

        case "resetPasswordseller":
        return resetPasswordUrl();

        case "loginseller":
        return loginUrl(); 

        case "loginManagerseller":
        return loginManagerUrl();

        case "logoutseller":
        return logoutUrl(); 

        case "insertAssetseller":
        return insertAssetUrl();

        case "retrieveAssetsellerwithextension":
        return retrieveAssetWithextensionUrl();

        case "retrieveAssetseller":
        return retrieveAssetUrl();

        case "displayAssetseller":
        return displayAssetUrl();

        case "uploadPicsseller":
        return uploadPicsUrl(); 

        case "uploadVideoseller":
        return uploadVideoUrl();  
        
        case "searchseller":
        return searchUrl();

        case "generateInvoiceseller":
            return generateInvoiceUrl();

        case "retrieveInvoicesseller":
            return getInvoiceUrl(); 

           case "retrieveInvoiceByNumberseller":
                return getInvoiceByNumberUrl(); 

         case "retrievePaymentValueByTicketId":
             return retrievePaymentByInvoiceIdUrl(); 

        case "generateFoodMenueseller":
            return generateFoodMenueUrl();
    
        case "retrieveFoodMenueseller":
            return getFoodMenueUrl();
          
        case "retrieveFoodMenueAsAChefseller":
            return getFoodMenueAsAChefUrl();
            
        
        case "generateOrderseller":
            return generateOrderUrl();
        
            case "retrieveOrderseller":
                return getOrderUrl(); 

        case "getAssetDetailByIdseller":
            return getAssetDetailByIdUrl(); 

        case "getPaymentCardseller":
            return getPaymentCardUrl(); 


        case "sendPaymentConfirmationseller":
            return sendPaymentConfirmationdUrl();                   
                
        }    
}
