import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import AllInvoicesCombined from "./AllInvoicesCombined";
import MarveltechIn from "./MarveltechIn";
import MarveltechOut from "./MarveltechOut";
import InvoiceDashboard from "./InvoiceDashboard";
import InvoiceByNumber from "./InvoiceByNumber";
import InvoiceByName from "./InvoiceByName";

class MainBodyInvoices extends Component {
  render() {
    return (
      <div>
        
        <HashRouter>          
          <Route path="/invoicedashboard" component={InvoiceDashboard} /> 
          <Route path="/marveltechin" component={MarveltechIn} /> 
          <Route path="/marveltechout" component={MarveltechOut} /> 
          <Route path="/allInvoicescombined" component={AllInvoicesCombined} /> 
          <Route path="/invoicesdashboard" component={InvoiceDashboard} /> 
          <Route path="/invoicebynumber" component={InvoiceByNumber} /> 
          <Route path="/invoicebyname" component={InvoiceByName} /> 
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyInvoices);
