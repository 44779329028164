
import { useTranslation } from 'react-i18next';
import { React,useState, useEffect} from "react";
import axios from "axios";
import Select from 'react-select';
import GetUrl from '../services/urlServiceBuyer';
import LocalStorageService from '../../services/localStorageService';
import {Row, Col} from 'react-bootstrap';

const MakePayment = () => {
  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');
  const [sortcode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [results, setResult] = useState([]);
 
  function getApiPath () { 
    return GetUrl("getPaymentCardhairstyle")
  };

  function loginToken (){
    return LocalStorageService("get", "token")
  }; 

  useEffect (() =>{
    setContactEmail(LocalStorageService("get", "userEmail"))
  }, )

  useEffect (() =>{
    fetchAccountDetails();
  },[] )
  
//  const email = loginEmail();
 const token = loginToken();
    function fetchAccountDetails () {
      const body ={
        title,
        fname,
        lname,
        invoiceId
      }
          const API_PATH = getApiPath();
          axios({
            method: "post",
            url: `${API_PATH}`            
          })
            .then((result) => {
              if (result.status === 200) {         
                setResult(result.data);       
              }
            })
            .catch(function (error) {    
              console.log(error);
            }); 
        }


return (
  <div  className="content-akwaba">
       {results.map((item, i) => {
          return (
          <div>
            <Row>           
             <Col md={{ span: 6, offset: 2 }}>  
            <div className ="invoices-container">
          <ul Style="list-style-type: none">         
            <li Style="color:Black"><h3><b>*******  Account details:    *******</b></h3></li>
            <li key={i}>Account Number: {item.Account}</li>
            <li key={i}>Sort: {item.Sort}</li> 
            <li key={i}>Bank: {item.Bank}</li>
            <li key={i}>Holder: {item.Holder}</li> 
            <p></p>
           </ul>
           </div>
            </Col>
           </Row>
         </div>
          )
        })}    
    </div>
)
}

export default MakePayment;

