import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "./pages/Services";
import Contact from "./pages/messages/SendContactMessage";
import Menue from "./pages/Menue";
import References from "./pages/References";
import Booking from "./pages/Booking";
import Appointments from "./pages/Appointments";
import Interets from "./pages/Interets";
import RegisterSimple from "./registerlogin/RegisterSimple";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import MessageMyAdmin from "./admin/myAdmin/MessageAdminPage";
import MyAdmin from "./admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./admin/myAdmin/ServiceSubscription";
import AllInvoicesCombined from "./admin/invoice/AllInvoicesCombined";
import Logout from "./registerlogin/Logout";
import Partnerservice from "./partners/Partnerservice";
import UsefullLinks from "./admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./loadPages/AssetDetailPageLive";
import UploadVideo from "./uploadMedia/UploadVideo";
import UploadPictures from "./uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyerService from "./buyers/BuyerServices";
import BuyerMessages from "./buyers/MyMessagePage";
import BuyerAppointments from "./buyers/MyAppointments";
import BuyerProperties from "./buyers/MyProperty";
import AppointmentMessages from "./admin/appointments/AppointmentMessagePage";
import BookingMessages from "./admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./admin/invoice/GenerateInvoice";
import InvoicePage from "./admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/ProductMenue/GenerateProductMenue";
import ProductMenueASAChefPage from "./admin/ProductMenue/ProductMenuePageAsProductOwner";
import ProductMenuePage from "./admin/ProductMenue/ProductMenuePage";
import GenerateOrder from "./admin/orders/GenerateOrder";
import OrdersPage from "./admin/orders/OrdersPage";
import MakePayment from "./payments/MakePayment";
import SendPaymentConfirmation from "./payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./admin/invoice/InvoiceByNumberPage";
import Help from "./help_merchandise"
import CollectPaymentNumber from "./payments/CollectPaymentNumber";
import MakePaymentRoot from "./payments/MakePaymentRoot";

class MainBodyMerchandise extends Component {
  render() {
    return (
      <div>
        
        <HashRouter> 
          <Route path="/helpcommodities" component={Help} />       
          <Route path="/appointmentmessagescommodities" component={AppointmentMessages} /> 
          <Route path="/bookingmessagescommodities" component={BookingMessages} /> 
          <Route path="/interestexpressedcommodities" component={ExpressedInterestMessages} />
          <Route path="/buyerservicescommodities" component={BuyerService} /> 
          <Route path="/buyerappointmentscommodities" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertiescommodities" component={BuyerProperties} /> 
          <Route path="/buyermessagescommodities" component={BuyerMessages} /> 
          <Route path="/uploadedthankscommodities" component={ThankYouUploaded} />
          <Route path="/assetdetailscommoditiesdev" component={AssetDetailsDev} />
          <Route path="/assetdetailscommoditieslive" component={AssetDetailsLive} />          
          <Route path="/servicescommodities" component={Services} />
          <Route path="/menuecommodities" component={Menue} />
          <Route path="/referencecommodities" component={References} />
          <Route path="/bookingcommodities" component={Booking} />
          <Route path="/appointmentscommodities" component={Appointments} />
          <Route path="/interetscommodities" component={Interets} /> 
          <Route path="/registersimplecommodities" component={RegisterSimple} />
          <Route path="/changepasswordcommodities" component={ChangePassword} />
          <Route path="/thankyouregisteredcommodities" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedcommodities" component={ThankyouPasswordChanged} />           
          <Route path="/adminpagecommodities" component={MyAdmin} />
          <Route path="/servicesubscriptioncommodities" component={ServiceSubscription} />
          <Route path="/servicesubscriptionallinvoices" component={AllInvoicesCombined} />
          <Route path="/loginscreencommodities" component={Loginscreen} />
          <Route path="/logincommodities" component={Login} />
          <Route path="/contactcommodities" component={Contact} />
          <Route path="/messageadmincommodities" component={MessageMyAdmin} />
          <Route path="/usefulllinkscommodities" component={UsefullLinks} />
          <Route path="/partnerservicecommodities" component={Partnerservice} />
          <Route path="/logoutcommodities" component={Logout} /> 
          <Route path="/uploadmerchandisedetails" component={UploadAssets} />
          <Route path="/uploadvideoscommodities" component={UploadVideo} />
          <Route path="/uploadpicturescommodities" component={UploadPictures} /> 
          <Route path="/generateinvoicecommodities" component={GenerateInvoice} /> 
          <Route path="/invoicepagecommodities" component={InvoicePage} />
          <Route path="/invoicebynumberpagecommodities" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenuecommodities" component={GenerateProductMenue} /> 
          <Route path="/productmenuepagecommodities" component={ ProductMenuePage} />
          <Route path="/productmenueasachefpagecommodities" component={ProductMenueASAChefPage} />
          <Route path="/generateordercommodities" component={GenerateOrder} /> 
          <Route path="/orderspagecommodities" component={OrdersPage} /> 
          <Route path="/makepaymentcommodities" component={MakePayment} />
          <Route path="/sendpaymentconfirmationcommodities" component={SendPaymentConfirmation} />          
          <Route path="/commoditypaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymentcom" component={MakePaymentRoot} />  
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyMerchandise);
