import React, { Component, useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from './InvoiceDataTable';
import LocalStorageService from '../services/localStorageService';
import GetUrl from "./services/urlServiceInvoices";
import CurrencyFormat from 'react-currency-format';
import Button from 'react-bootstrap/Button';
import { Form, FormControl } from "react-bootstrap";
import InvoiceByNumber from './InvoiceByNumber';

const AllInvoicesCombined = () => { 
  
  const getApiPath = (type) => GetUrl(type);

  const getSearchInvoiceNumber = () => {
    const url =  window.location.href; // Capture full query string
   // console.log("Full URL Query:", url); // Capture full query string
  
    if (url.includes("=")) {
      const searchInvoiceNumber = url.split("=")[1]; // Get the value after '='
      console.log("Extracted Invoice Number:", searchInvoiceNumber);
      return searchInvoiceNumber || "";
    }
  
    console.warn("No invoice number found in the URL.");
    return "";
  };
  
  const [urls, setUrls] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [messageSent, setMessageSent] = useState('');
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState(getSearchInvoiceNumber());
  const [alternativeInvoiceNumber, setAlternativeInvoiceNumber] = useState("");    
  const [results, setResults] = useState([]);
  const [singleInvoice, setSingleInvoice] = useState([]); 
  const[jsonArray, setJonArray] = useState([]); 

function getToken (){
return LocalStorageService("get", "token"); 
}; 

const isFetched = useRef(false);

useEffect(() => {
  if (!isFetched.current) {
    retrieveInvoice();
    isFetched.current = true;
  }
}, []);
 

const retrieveInvoice = async () => {
  try {
    const API_PATH = getApiPath("retrieveInvoicesAll");
    const response = await axios.post(`${API_PATH}`);

    if (response.status === 200) {
      setMessageSent(true);
      setResults(response.data);
    }
  } catch (error) {
    console.error("Error retrieving invoices:", error);
  }
};

    
const retrieveInvoiceByNumber = useCallback(() => {
  const API_PATH = getApiPath("retrieveInvoiceByNumber");

  const url = window.location.href; 
    console.log("Full URL Query:", url);
    setUrls(url)
    if (url.includes("=")) {
      const searchInvoiceNumber = url.split("=")[1]; // Get the value after '='
      console.log("Extracted Invoice Number:", searchInvoiceNumber);
      setSearchInvoiceNumber(searchInvoiceNumber);
    }
  
  if (searchInvoiceNumber) {
    axios({
      method: "post",
      url: `${API_PATH}`,
      data: { searchInvoiceNumber },
    })
      .then((result) => {
        if (result.status === 200) {
          setInvoices(result?.data);
          setSingleInvoice(true); 
          setAlternativeInvoiceNumber(searchInvoiceNumber); 
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

useEffect(() => {
  retrieveInvoiceByNumber();
}, [searchInvoiceNumber]);

const hasValidItems = (item) => {
  return (
    (item.Item1Qty > 0 && item.Item1Price > 0) ||
    (item.Item2Qty > 0 && item.Item2Price > 0) ||
    (item.Item3Qty > 0 && item.Item3Price > 0) ||
    (item.Item4Qty > 0 && item.Item4Price > 0) ||
    (item.Item5Qty > 0 && item.Item5Price > 0) ||
    (item.Item6Qty > 0 && item.Item6Price > 0)
  );
};
 
return (
  <div  className="content-akwaba">  
    {/* Search Input */}
      <div className="mb-3 w-90">
        <Form.Control
          type="text"
          placeholder="Invoice Number"
          value={searchInvoiceNumber}
          onChange={(e) => setSearchInvoiceNumber(e.target.value)}       
        />
      </div>  
      <Button onClick={retrieveInvoiceByNumber} variant="primary">Search</Button>
      {messageSent && (<div>
    {alternativeInvoiceNumber && (
    <div className="display-invoice-link">
      <strong>Invoice Number: {searchInvoiceNumber}</strong>
      <strong>Invoice Page URL:</strong> 
      <a 
        href={`/#/invoicesdashboard?invoiceNumber=${encodeURIComponent(alternativeInvoiceNumber)}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="d-block text-primary"
      >
        {window.location.origin}/#/invoicesdashboard?invoiceNumber={encodeURIComponent(alternativeInvoiceNumber)}
      </a>
    </div>
  )}
   
    </div>)}
        {Array.isArray(results) && results.length > 0 ? (results.map((item, i) => {
          if (!hasValidItems(item)) return null; // Skip invoices without valid items
         return (              
    <div key={i+""} className="content-invoice">
       <Row key={i+""} className="justify-content-center">
        <Col md={{ span: 12, offset: 2 }}>
      <div className="invoice-wrapper">
        <ul style={{ listStyleType: "none" }}>
          {/* Invoice Details */}
          <li style={{ listStyleType: "none" }}>
          <div className="invoice-header">
              <p>Invoice ID: {item.Id}</p>
              <p>Invoice Number: {item.InvoiceNumber}</p>
              <p>Invoice Status: {item.InvoiceStatus}</p>
            </div>
          </li>

          {/* Created At */}
          <li key={i + "-created"}>Created At: {item.created_at}</li>

          {/* Company Info */}
          <li style={{ color: "purple" }}>
            <h4><b>Company Info</b></h4>
          </li>
          <li key={i + "-company-name"}>Your Service Name: {item.CatererCompanyName}</li>
          <li key={i + "-company-number"}>
            Company Number:{" "}
            {item.CatererCompanyName?.toLowerCase().includes("marveltech")
              ? "26357/GTCA/RC/2022"
              : item.OtherCompanyNumber}
          </li>
          <p></p>
          {/* Purchase Info */}
          <li style={{ color: "burgundi" }}>
            <h4><b>Purchase Info</b></h4>
          </li>
          <li key={i + "-dish-name"}>Product Name: {item.DishName}</li>
          <li key={i + "-customer-phone"}>Customer Phone: {item.CustomerPhoneNumber}</li>

          {/* Dynamic Items Rendering */}
          {[1, 2, 3, 4, 5, 6].map((num) => {
            const itemName = item[`Item${num}Name`];
            const itemPrice = item[`Item${num}Price`];
            const itemQty = item[`Item${num}Qty`];

            return itemName && itemQty > 0 && itemPrice > 0 ? (
              <React.Fragment key={i + `-item${num}`}>
                <li>Item{num} Name: {itemName}</li>
                <li>Item{num} Price: £{itemPrice}</li>
                <li>Item{num} Qty: {itemQty}</li>
              </React.Fragment>
            ) : null;
          })}
          <p></p>
      <li style={{ color: "black" }}>
            <h4><b>Summary</b></h4>
          </li>
          <li key={i+ "Total"}>Total: £{item.total}</li>
          <li key={i+ "VAT"}>VAT: £{item.VAT}</li>
          <li key={i+ "Grand_total"}>Grand_total: £{item.Grand_total}</li>
           <p></p>
           <p></p>         
          <li style={{ color: "teal" }}><h4><b>Invoice info</b></h4></li>          
          <li key={i+ "Paid-Status"}>Paid Status: {item.PaidStatus==1?'Paid':'Unpaid'}</li>
          <li key={i+ "Date-Paid"}>Date Paid: {item.DatePaid?item.DatePaid:'N/A'}</li>         
           <li key={i+ "Refund-Status"}>Refund Status: {item.RefundStatus==1?'Refunded':'N/A'}</li>
           <li key={i+ "Date-Refund"}>Date Refund: {item.DateRefund?item.DateRefund:'N/A'}</li>
          <p></p>         
          <li>**********************************************</li>
        </ul>
      </div>
       </Col>
       </Row>
    </div>   
  );
}) ) : (
  <p>No more results found.</p>)  
  } 
     
</div> 
       
)  
         
}
export default AllInvoicesCombined;