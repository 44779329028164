import { useTranslation } from 'react-i18next';
import { React,useState} from "react";
import axios from "axios";
// import GetApis from '../pages/GetApis';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import {NavLink, HashRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router";
import GlobalConfig from "../config.json";
import GetEnvironment from "../services/getEnvironment"

const SuperUserDashboard = () => {

        const { t } = useTranslation(); 

        

        function navigateToInvoices (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptionallinvoices'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptionallinvoices'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptionallinvoices'
        }

        function navigateToInvoicesDashboard (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'invoicesdashboard'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'invoicesdashboard'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'invoicesdashboard'
        }
        
        
        function navigateToMessages (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'messagedashboard'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'messagedashboard'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'messagedashboard'
        }

        function navigateToUsers (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'usersservice'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'usersservice'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'usersservice'
        }
      
        function navigateToDiy (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptionassembling'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptionassembling'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptionassembling'
        }

        function navigateToBeauty (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptionhairstyle'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptionhairstyle'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptionhairstyle'
        }

        function navigateToCatering (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptioncatering'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptioncatering'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptioncatering'
        }

        function navigateToSupplier (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptioncommodities'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptioncommodities'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptioncommodities'
        }

        function navigateToBuyer (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptionbuyer'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptionbuyer'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptionbuyer'
        }
        
        function navigateToSeller (event){
            if(GetEnvironment()=="dev")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'servicesubscriptionseller'
            if(GetEnvironment()=="live")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'servicesubscriptionseller'
            if(GetEnvironment()=="test")
            window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'servicesubscriptionseller'
        }

        
        return (             
        <div className='justify-content'>
        <Row className="margin-maker">    
        <Col md={{ span: 4, offset: 4}}>    
        <Button variant="warning" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToMessages(true)} type ='submit'> Messages</Button> 
        <Button variant="warning" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToInvoices(true)} type ='submit'> Invoices</Button> 
        <Button variant="warning" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToInvoicesDashboard(true)} type ='submit'> Invoices Dashboard</Button> 
        <Button variant="warning" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToUsers(true)} type ='submit'> Users</Button>
        </Col>        
        </Row>
        <Row className="margin-maker">    
        <Col md={{ span: 4, offset: 4}}>  
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToDiy(true)} type ='submit'> DIY</Button> 
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToBeauty(true)} type ='submit'> Beauty</Button>
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToCatering(true)} type ='submit'> Catering</Button> 
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToSupplier(true)} type ='submit'>Business hub</Button>
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToBuyer(true)} type ='submit'> Wanted items</Button> 
        <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>navigateToSeller(true)} type ='submit'> For Sales</Button>
        </Col>        
        </Row>
        </div>
         )
    }  

export default SuperUserDashboard
