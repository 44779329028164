import config from '../invoice_config.json';
import GetEnvironment from './getEnvironment';

function retrieveAssetWithextensionUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.RETRIEVEASSETWITHEXTENSION;
        case "live":
        return config.DIRECT_LIVE.RETRIEVEASSETWITHEXTENSION;
        case "test":
        return config.DIRECT_TEST.RETRIEVEASSETWITHEXTENSION;
        }
}

function searchUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.SEARCH; 
        case "live":
        return config.DIRECT_LIVE.SEARCH; 
        case "test":
        return config.DIRECT_TEST.SEARCH;
        } 
}

function getInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETINVOICE; 
        case "live":
        return config.DIRECT_LIVE.GETINVOICE; 
        case "test":
        return config.DIRECT_TEST.GETINVOICE;
        }        
}

function getInvoiceByNumberUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETINVOICEBYNUMBER; 
        case "live":
        return config.DIRECT_LIVE.GETINVOICEBYNUMBER; 
        case "test":
        return config.DIRECT_TEST.GETINVOICEBYNUMBER;
        }        
}

function getInvoiceByNameUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.MANUAL_URL.GETINVOICEBYNAME; 
        case "live":
        return config.DIRECT_LIVE.GETINVOICEBYNAME; 
        case "test":
        return config.DIRECT_TEST.GETINVOICEBYNAME;
        }        
}

  function getAllInvoiceUrl(){
            switch(GetEnvironment()){
                case "dev":
                return config.MANUAL_URL.GETALLINVOICE; 
                case "live":
                return config.DIRECT_LIVE.GETALLINVOICE; 
                case "test":
                return config.DIRECT_TEST.GETALLINVOICE;
                }  
             }


export default function GetUrl(targetUrl){
    switch(targetUrl){
        case "retrieveAssetinvoicewithextension":
        return retrieveAssetWithextensionUrl();
        
        case "searchinvoice":
        return searchUrl();

        case "retrieveInvoices":
        return getInvoiceUrl(); 

        case "retrieveInvoiceByNumber":
        return getInvoiceByNumberUrl();           
            
        case "retrieveInvoiceByName":
            return getInvoiceByNameUrl(); 
        
        case "retrieveInvoicesAll":
        return getAllInvoiceUrl(); 
        }    
}
