import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import SellerServices from "./pages/SellerServices";
import Contact from "./pages/messages/SendContactMessage";
import Menue from "./pages/Menue";
import References from "./pages/References";
import Booking from "./pages/Booking";
import Appointments from "./pages/Appointments";
import Interets from "./pages/Interets";
import RegisterSimple from "./registerlogin/RegisterSimple";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import MessageMyAdmin from "./admin/myAdmin/MessageAdminPage";
import MyAdmin from "./admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./admin/myAdmin/ServiceSubscription";
import Logout from "./registerlogin/Logout";
import Partnerservice from "./partners/Partnerservice";
import UsefullLinks from "./admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./loadPages/AssetDetailPageLive";
import UploadVideo from "./uploadMedia/UploadVideo";
import UploadPictures from "./uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
// import BuyerService from "./buyers/BuyerServices";
// import BuyerMessages from "./buyers/MyMessagePage";
// import BuyerAppointments from "./buyers/MyAppointments";
// import BuyerProperties from "./buyers/MyProperty";
import AppointmentMessages from "./admin/appointments/AppointmentMessagePage";
import BookingMessages from "./admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./admin/invoice/GenerateInvoice";
import InvoicePage from "./admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/ProductMenue/GenerateProductMenue";
import ProductMenueASAChefPage from "./admin/ProductMenue/ProductMenuePageAsProductOwner";
import ProductMenuePage from "./admin/ProductMenue/ProductMenuePage";
import GenerateOrder from "./admin/orders/GenerateOrder";
import OrdersPage from "./admin/orders/OrdersPage";
import MakePayment from "./payments/MakePayment";
import SendPaymentConfirmation from "./payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./admin/invoice/InvoiceByNumberPage";
import Help from "./help_seller"
import CollectPaymentNumber from "./payments/CollectPaymentNumber";
import MakePaymentRoot from "./payments/MakePaymentRoot";

class MainBodySeller extends Component {
  render() {
    return (
      <div>
        
        <HashRouter> 
          <Route path="/helpseller" component={Help} />       
          <Route path="/appointmentmessagesseller" component={AppointmentMessages} /> 
          <Route path="/bookingmessagesseller" component={BookingMessages} /> 
          <Route path="/interestexpressedseller" component={ExpressedInterestMessages} />
          {/* <Route path="/buyerservicesseller" component={BuyerService} /> 
          <Route path="/buyerappointmentsseller" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertiesseller" component={BuyerProperties} /> 
          <Route path="/buyermessagesseller" component={BuyerMessages} />  */}
          <Route path="/uploadedthanksseller" component={ThankYouUploaded} />
          <Route path="/assetdetailssellerdev" component={AssetDetailsDev} />
          <Route path="/assetdetailssellerlive" component={AssetDetailsLive} />          
          <Route path="/servicesseller" component={SellerServices} />
          <Route path="/menueseller" component={Menue} />
          <Route path="/referenceseller" component={References} />
          <Route path="/bookingseller" component={Booking} />
          <Route path="/appointmentsseller" component={Appointments} />
          <Route path="/interetsseller" component={Interets} /> 
          <Route path="/registersimpleseller" component={RegisterSimple} />
          <Route path="/changepasswordseller" component={ChangePassword} />
          <Route path="/thankyouregisteredseller" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedseller" component={ThankyouPasswordChanged} />           
          <Route path="/adminpageseller" component={MyAdmin} />
          <Route path="/servicesubscriptionseller" component={ServiceSubscription} />
          <Route path="/loginscreenseller" component={Loginscreen} />
          <Route path="/loginseller" component={Login} />
          <Route path="/contactseller" component={Contact} />
          <Route path="/messageadminseller" component={MessageMyAdmin} />
          <Route path="/usefulllinksseller" component={UsefullLinks} />
          <Route path="/partnerserviceseller" component={Partnerservice} />
          <Route path="/logoutseller" component={Logout} /> 
          <Route path="/uploadmerchandisedetails" component={UploadAssets} />
          <Route path="/uploadvideosseller" component={UploadVideo} />
          <Route path="/uploadpicturesseller" component={UploadPictures} /> 
          <Route path="/generateinvoiceseller" component={GenerateInvoice} /> 
          <Route path="/invoicepageseller" component={InvoicePage} />
          <Route path="/invoicebynumberpageseller" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenueseller" component={GenerateProductMenue} /> 
          <Route path="/productmenuepageseller" component={ ProductMenuePage} />
          <Route path="/productmenueasachefpageseller" component={ProductMenueASAChefPage} />
          <Route path="/generateorderseller" component={GenerateOrder} /> 
          <Route path="/orderspageseller" component={OrdersPage} /> 
          <Route path="/makepaymentseller" component={MakePayment} />
          <Route path="/sendpaymentconfirmationseller" component={SendPaymentConfirmation} />          
          <Route path="/sellerpaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymentcom" component={MakePaymentRoot} />  
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodySeller);
