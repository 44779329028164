import { useState, useEffect } from "react";
import GenerateInvoiceMerchandise from "../clientappMerchandise/admin/invoice/GenerateInvoice";
import GenerateInvoiveDIY from "../clientappDIYTechnicians/admin/invoice/GenerateInvoice";
import GenerateInvoiveHair from "../clientappHairtechnicians/admin/invoice/GenerateInvoice";
import GenerateInvoiveCatering from "../clientappCatering/admin/invoice/GenerateInvoice";
import GenerateInvoiveSeller from "../clientAppSellers/admin/invoice/GenerateInvoice";
import GenerateInvoiveBuyer from "../buyers/admin/invoice/GenerateInvoice";
import GenerateInvoiveConnections from "../clientappConnections/admin/invoice/GenerateInvoice";

const BusinessSelectorParent = () => {
    const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
        fetch("/common_Config.json")
            .then(response => response.json())
            .then(data => {
              console.log("businesses from parent: ", data.BUSINESSES )
                if (data.BUSINESSES) {
                    setBusinesses(Object.entries(data.BUSINESSES)); // Convert object to array of [key, value] pairs
                }
            })
            .catch(error => console.error("Error loading config:", error));
    }, []);

    return (
        <div>
            {/* <GenerateInvoiceMerchandise businesses= {businesses} /> */}
            <GenerateInvoiveDIY businesses={businesses} />
            <GenerateInvoiveHair businesses={businesses} />
            <GenerateInvoiveCatering businesses={businesses} />
            <GenerateInvoiveSeller businesses={businesses} />
            <GenerateInvoiveBuyer businesses={businesses} />
            <GenerateInvoiveConnections businesses={businesses} />
        </div>
    );
};

export default BusinessSelectorParent;
