import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "./pages/Services";
import Contact from "./pages/messages/SendContactMessage";
import Menue from "./pages/Menue";
import References from "./pages/References";
import Booking from "./pages/Booking";
import Appointments from "./pages/Appointments";
import Interets from "./pages/Interets";
import RegisterSimple from "./registerlogin/RegisterSimple";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import MessageMyAdmin from "./admin/myAdmin/MessageAdminPage";
import MyAdmin from "./admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./admin/myAdmin/ServiceSubscription";
import Logout from "./registerlogin/Logout";
import Partnerservice from "./partners/Partnerservice";
import UsefullLinks from "./admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./loadPages/AssetDetailPageLive";
import UploadVideo from "./uploadMedia/UploadVideo";
import UploadPictures from "./uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
// import BuyerService from "./buyers/BuyerServices";
// import BuyerMessages from "./buyers/MyMessagePage";
// import BuyerAppointments from "./buyers/MyAppointments";
// import BuyerProperties from "./buyers/MyProperty";
import AppointmentMessages from "./admin/appointments/AppointmentMessagePage";
import BookingMessages from "./admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./admin/invoice/GenerateInvoice";
import InvoicePage from "./admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/ProductMenue/GenerateProductMenue";
import ProductMenueASAChefPage from "./admin/ProductMenue/ProductMenuePageAsProductOwner";
import ProductMenuePage from "./admin/ProductMenue/ProductMenuePage";
import GenerateOrder from "./admin/orders/GenerateOrder";
import OrdersPage from "./admin/orders/OrdersPage";
import MakePayment from "./payments/MakePayment";
import SendPaymentConfirmation from "./payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./admin/invoice/InvoiceByNumberPage";
import Help from "./help_buyer"
import CollectPaymentNumber from "./payments/CollectPaymentNumber";
import MakePaymentRoot from "./payments/MakePaymentRoot";

class MainBodyMerchandise extends Component {
  render() {
    return (
      <div>
        
        <HashRouter> 
          <Route path="/helpbuyer" component={Help} />       
          <Route path="/appointmentmessagesbuyer" component={AppointmentMessages} /> 
          <Route path="/bookingmessagesbuyer" component={BookingMessages} /> 
          <Route path="/interestexpressedbuyer" component={ExpressedInterestMessages} />
          {/* <Route path="/buyerservicesbuyer" component={BuyerService} /> 
          <Route path="/buyerappointmentsbuyer" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertiesbuyer" component={BuyerProperties} /> 
          <Route path="/buyermessagesbuyer" component={BuyerMessages} />  */}
          <Route path="/uploadedthanksbuyer" component={ThankYouUploaded} />
          <Route path="/assetdetailsbuyerdev" component={AssetDetailsDev} />
          <Route path="/assetdetailsbuyerlive" component={AssetDetailsLive} />          
          <Route path="/servicesbuyer" component={Services} />
          <Route path="/menuebuyer" component={Menue} />
          <Route path="/referencebuyer" component={References} />
          <Route path="/bookingbuyer" component={Booking} />
          <Route path="/appointmentsbuyer" component={Appointments} />
          <Route path="/interetsbuyer" component={Interets} /> 
          <Route path="/registersimplebuyer" component={RegisterSimple} />
          <Route path="/changepasswordbuyer" component={ChangePassword} />
          <Route path="/thankyouregisteredbuyer" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedbuyer" component={ThankyouPasswordChanged} />           
          <Route path="/adminpagebuyer" component={MyAdmin} />
          <Route path="/servicesubscriptionbuyer" component={ServiceSubscription} />
          <Route path="/loginscreenbuyer" component={Loginscreen} />
          <Route path="/loginbuyer" component={Login} />
          <Route path="/contactbuyer" component={Contact} />
          <Route path="/messageadminbuyer" component={MessageMyAdmin} />
          <Route path="/usefulllinksbuyer" component={UsefullLinks} />
          <Route path="/partnerservicebuyer" component={Partnerservice} />
          <Route path="/logoutbuyer" component={Logout} /> 
          <Route path="/uploadmerchandisedetails" component={UploadAssets} />
          <Route path="/uploadvideosbuyer" component={UploadVideo} />
          <Route path="/uploadpicturesbuyer" component={UploadPictures} /> 
          <Route path="/generateinvoicebuyer" component={GenerateInvoice} /> 
          <Route path="/invoicepagebuyer" component={InvoicePage} />
          <Route path="/invoicebynumberpagebuyer" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenuebuyer" component={GenerateProductMenue} /> 
          <Route path="/productmenuepagebuyer" component={ ProductMenuePage} />
          <Route path="/productmenueasachefpagebuyer" component={ProductMenueASAChefPage} />
          <Route path="/generateorderbuyer" component={GenerateOrder} /> 
          <Route path="/orderspagebuyer" component={OrdersPage} /> 
          <Route path="/makepaymentbuyer" component={MakePayment} />
          <Route path="/sendpaymentconfirmationbuyer" component={SendPaymentConfirmation} />          
          <Route path="/buyerpaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymentcom" component={MakePaymentRoot} />  
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyMerchandise);
