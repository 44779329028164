import { React, useState } from 'react';
import LocalStorageService from '../services/localStorageService';
import AllInvoicesCombined from "./AllInvoicesCombined";
import MarveltechIn from "./MarveltechIn";
import MarveltechOut from "./MarveltechOut";
import InvoiceByName from "./InvoiceByName";
import InvoiceByNumber from "./InvoiceByNumber";

function InvoiceDashboard(){

    function loginToken (){
        return LocalStorageService("get", "token")
      }; 

    const token = loginToken();
    const isLoggedin = token!=null;

    const [toggleState, setToggleState] = useState(1);

    const  toggleTab =(index) => {
                setToggleState(index);
            }

    return (
       <div className ="container">
         {isLoggedin && (<div>
        <div className ="bloc-tabs">
        <div className={toggleState===1 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(1)}>
         Combined
        </div>
        <div className={toggleState===2 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(2)}>
        Marveltech In
        </div>
        <div className={toggleState===3 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(3)}>
        Marveltech Out
        </div> 
        
        <div className={toggleState===4 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(4)}>
        By Name
        </div> 
        <div className={toggleState===5 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(5)}>
        By Number
        </div>            
       </div>

        <div className ="content-tabs">
            <div className={toggleState===1 ? "content active-content":"content"}>
                <h2>Combined </h2>                 
                 <AllInvoicesCombined/>
            </div>

            <div className={toggleState===2 ? "content active-content":"content"}>
                <h2>Marveltech In</h2>
               < MarveltechIn/>
            </div>

            <div className={toggleState===3 ? "content active-content":"content"}>
                <h2>Marveltech out</h2>               
                <MarveltechOut/>
            </div>
           

            <div className={toggleState===4 ? "content active-content":"content"}>
                <h2>By name </h2>
               < InvoiceByName/>
            </div>

            <div className={toggleState===5 ? "content active-content":"content"}>
                <h2>By number</h2>               
                <InvoiceByNumber/>
            </div>
        </div>
        </div>
         )} 
        </div>   
       
    )
}

export default InvoiceDashboard