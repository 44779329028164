import {React, useState, useEffect} from 'react';
import styled from 'styled-components';
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import LocalStorageService from '../../services/localStorageService';
import Button from 'react-bootstrap/Button';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    padding-top: 3.5rem;
    z-index:1;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

function RightNav ({open } )  {
    const { t } = useTranslation();

    const[longStatus, setLoginStatus] = useState('');
    const[liveToken, setLiveToken] = useState('');

    var loginToken = LocalStorageService("get","token");
    var userType = LocalStorageService("get","userType");
   var userAdmin = userType =='A';
   var userCustomer = userType =='C';
    useEffect(()=>{
      updateLoginStatus();
    },[])

function updateLoginStatus () {
  setLoginStatus(LocalStorageService("get","token"));
}

      return (
    <div>      
      <Row>      
       <Col md={{ span: 12, offset: 0 }}>      
        <Ul open={open} id="menu"> 
        <HashRouter>
          <p></p>         
                   <li>
                      <Dropdown>
                          <Dropdown.Toggle variant="" id="">
                         <Button variant = "">{t("navbar.services")}</Button> 
                          </Dropdown.Toggle>

                           <Dropdown.Menu>
                           { userAdmin && ( <Dropdown.Item href="#"><NavLink to="/serviceSubscriptioncommodities">
                            <span className="header-akwaba-rightnavbar-navlinks">
                           My DASHBOARD
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>)}
                      
                           <Dropdown.Item href="#"><NavLink to="/bookingcommodities">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Make a booking as Business hub
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item> 
                           <Dropdown.Item href="#"><NavLink to="/appointmentscommodities">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Make an appointment as Business hub
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>
                            {!loginToken && (<Dropdown.Item href="#"><NavLink to="/commoditypaymentnumber">
                            <span className ="header-akwaba-rightnavbar-navlinks">
                             Make a payment</span></NavLink>            
                                </Dropdown.Item>)}                          
                          </Dropdown.Menu>  

                      </Dropdown>
                    </li>               
                                     
                    <li>                           
                          <Dropdown>
                          <Dropdown.Toggle variant="" id="">                          
                          <Button variant = "" onClick ={updateLoginStatus}>{t("navbar.account")}</Button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                          { !loginToken && (<Dropdown.Item href="#"> <NavLink to="/registersimplecommodities">
                              <span className ="header-akwaba-rightnavbar-navlinks">
                               Create an account as Business hub</span></NavLink>
                                </Dropdown.Item>)}
                                { !loginToken && (<Dropdown.Item href="#"><NavLink to="/logincommodities">
                             <span className ="header-akwaba-rightnavbar-navlinks">
                                {/* {t("navbar.login")} */}Log as business owner</span></NavLink>            
                                 </Dropdown.Item>)}
                                 {loginToken && (<Dropdown.Item href="#"><NavLink to="/logoutcommodities">
                            <span className ="header-akwaba-rightnavbar-navlinks">
                              Log out</span></NavLink>            
                                </Dropdown.Item>)}
                                <Dropdown.Divider />
                                {loginToken && userCustomer &&( <Dropdown.Item href="#"> <NavLink to="/buyerservicescommodities">
                                  <span className ="header-akwaba-rightnavbar-navlinks">
                                    {t("navbar.youraccount")}</span></NavLink>            
                                </Dropdown.Item>)}                                
                          </Dropdown.Menu>
                          </Dropdown>                         
                    </li>                    
                    {/* <li>                   
                    <Dropdown>
                          <Dropdown.Toggle variant="" id="">
                          <Button variant = ""> {t("navbar.contact")}</Button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                           <Dropdown.Item href="#"> <NavLink to="/contactcommodities">
                            <span className ="header-akwaba-rightnavbar-navlinks">
                              {t("navbar.contactus")}</span></NavLink> 
                         </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown> 
                    </li> */}
                                                                 
        </HashRouter>       
        </Ul>
      </Col>
      </Row>  
  </div>
  )
}
export default (RightNav);