import React, {Component,  useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, Link, HashRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import LocalStorageService from './services/localStorageService';
import DashboardCarousel from './DashboardCarousel';
import FindMoreImage from './images/findoutmore.png';
class HomeDashboard extends Component {
  render() {
    const { t } = this.props;
    LocalStorageService("remove","servicecategory")
    return (
      <div>       
      <DashboardCarousel/>
       <HashRouter>
 <div className="container">
  <div className="row justify-content-md-center">
  <div className="col col-lg-3 m1" id="col-dashboard-1">            
        {/* <NavLink to="/allcategories"><h4><p className="a">Creativity</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink>  */}
         <NavLink to="/hairandbeauty"><span className ="header-akwaba-tiles-navlinks1">▶️Hair▫And▫Beauty</span></NavLink>            
             

        <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m2" id="col-dashboard-2">            
        {/* <NavLink to="/entertainment"><h4><p className="a">Spectacles</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink>  */}
            <NavLink to="/merchandise"><span className ="header-akwaba-tiles-navlinks2">▶️Business▫Hub</span></NavLink>            
             
        <div className="placeholder-dashboard"></div>
    </div>    
    </div>
  {/* </div> */}

  <div className="row justify-content-md-center">
    <div className="col col-lg-3 m3" id="col-dashboard-3">
        {/* <NavLink to="/entertainment"><h4><p className="a">Music</p></h4>
          <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink>         */}

<NavLink to="/DIYTechnicians"><span className ="header-akwaba-tiles-navlinks3">▶️DIY▫Experts</span></NavLink>            
            
        <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m4" id="col-dashboard-4">
        {/* <NavLink to="/parties"><h4><p className="a">Parties</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink>         */}
          <NavLink to="/catering"><span className ="header-akwaba-tiles-navlinks4">▶️Catering</span></NavLink>            
          
         <div className="placeholder-dashboard"></div>
    </div>
   
  </div>

 <div className="row justify-content-md-center">
    <div className="col col-lg-3 m5" id="col-dashboard-5">
      {/* <NavLink to="/allcategories"><h4><p className="a">Fairs</p></h4> 
      <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
      </NavLink>  */}
          <NavLink to="/buyerservice"><span className ="header-akwaba-tiles-navlinks4">▶️Wanted▫Items▫Service</span></NavLink>            
          
       <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m6" id="col-dashboard-6">        
       {/* <NavLink to="/trainings"><h4><p className="a">Trainings</p></h4>
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
        </NavLink>  */}
            <NavLink to="clientAppSellerservice"><span className ="header-akwaba-tiles-navlinks4">▶️For▫Sale</span></NavLink>            
          
      <div className="placeholder-dashboard"></div>
    </div>   
  </div>
</div>
<br/> <br/>
     </HashRouter> 
     </div> 
      
    );
  }
}

export default withTranslation() (HomeDashboard);
