import React, { Component, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from './InvoiceDataTable';
import LocalStorageService from '../services/localStorageService';
import GetUrl from "./services/urlServiceInvoices";
import CurrencyFormat from 'react-currency-format';
import Button from 'react-bootstrap/Button';
import { Form, FormControl } from "react-bootstrap";


const MarveltechIn = () => { 
  
  const getApiPath = (type) => GetUrl(type);

  // const getsearchInvoiceName = () => {
  //   const url =  window.location.href; // Capture full query string
  //   //console.log("Full URL Query:", url); // Capture full query string
  
  //   if (url.includes("=")) {
  //     const searchInvoiceName = url.split("=")[1]; // Get the value after '='
  //     console.log("Extracted Invoice Number:", searchInvoiceName);
  //     return searchInvoiceName || "";
  //   }
  
  //   console.warn("No invoice number found in the URL.");
  //   return "";
  // };
  // // $data,
  //$totalGrandSum

  //const [searchInvoiceName, setsearchInvoiceName] = useState(getsearchInvoiceName());
  const [alternativeInvoiceName, setalternativeInvoiceName] = useState("");    
  const [results, setResults] = useState([]);
  const [grandTotal, setGrandTotal] = useState();
  const [singleInvoice, setSingleInvoice] = useState([]); 
 
function getToken (){
return LocalStorageService("get", "token"); 
}; 

    
const retrieveInvoiceByName = async() => {
  const API_PATH = getApiPath("retrieveInvoiceByName");

    const body ={
      searchInvoiceName:"MarveltechIn",
      number:""
    }
    axios({
      method: "post",
      url: `${API_PATH}`,
      data: body,
    })
      .then((result) => {
        if (result.status === 200) {
          setResults(result?.data[0]);
          setGrandTotal(result?.data[1]); 
          console.log("result: ", {result});        
        }       
      })
      .catch((error) => {
        console.log(error);
      });
};

useEffect(() => {
  retrieveInvoiceByName();
}, []);

const hasValidItems = (item) => {
  return (
    (item.Item1Qty > 0 && item.Item1Price > 0) ||
    (item.Item2Qty > 0 && item.Item2Price > 0) ||
    (item.Item3Qty > 0 && item.Item3Price > 0) ||
    (item.Item4Qty > 0 && item.Item4Price > 0) ||
    (item.Item5Qty > 0 && item.Item5Price > 0) ||
    (item.Item6Qty > 0 && item.Item6Price > 0)
  );
};
 
return (
  <div  className="content-akwaba">    
       <div>Grand Total: {grandTotal}</div> 
        {Array.isArray(results) && results.length > 0 ? (results.map((item, i) => {
          if (!hasValidItems(item)) return null; // Skip invoices without valid items
         return (   
    <div key={i+""} className="content-invoice">      
       <Row key={i+""} className="justify-content-center">
        <Col md={{ span: 12, offset: 2 }}>
      <div className="invoice-wrapper">
        <ul style={{ listStyleType: "none" }}>
          {/* Invoice Details */}
          <li style={{ listStyleType: "none" }}>
          <div className="invoice-header">              
              <p>Invoice ID: {item.Id}</p>
              <p>Invoice Number: {item.InvoiceNumber}</p>
              <p>Invoice Status: {item.InvoiceStatus}</p>
            </div>
          </li>

          {/* Created At */}
          <li key={i + "-created"}>Created At: {item.created_at}</li>

          {/* Company Info */}
          <li style={{ color: "purple" }}>
            <h4><b>Company Info</b></h4>
          </li>
          <li key={i + "-company-name"}>Your Service Name: {item.CatererCompanyName}</li>
          <li key={i + "-company-number"}>
            Company Number:{" "}
            {item.CatererCompanyName?.toLowerCase().includes("marveltech")
              ? "26357/GTCA/RC/2022"
              : item.OtherCompanyNumber}
          </li>
          <p></p>
          {/* Purchase Info */}
          <li style={{ color: "burgundi" }}>
            <h4><b>Purchase Info</b></h4>
          </li>
          <li key={i + "-dish-name"}>Product Name: {item.DishName}</li>
          <li key={i + "-customer-phone"}>Customer Phone: {item.CustomerPhoneNumber}</li>

          {/* Dynamic Items Rendering */}
          {[1, 2, 3, 4, 5, 6].map((num) => {
            const itemName = item[`Item${num}Name`];
            const itemPrice = item[`Item${num}Price`];
            const itemQty = item[`Item${num}Qty`];

            return itemName && itemQty > 0 && itemPrice > 0 ? (
              <React.Fragment key={i + `-item${num}`}>
                <li>Item{num} Name: {itemName}</li>
                <li>Item{num} Price: £{itemPrice}</li>
                <li>Item{num} Qty: {itemQty}</li>
              </React.Fragment>
            ) : null;
          })}
          <p></p>
      <li style={{ color: "black" }}>
            <h4><b>Summary</b></h4>
          </li>
          <li key={i+ "Total"}>Total: £{item.total}</li>
          <li key={i+ "VAT"}>VAT: £{item.VAT}</li>
          <li key={i+ "Grand_total"}>Grand_total: £{item.Grand_total}</li>
           <p></p>
           <p></p>         
          <li style={{ color: "teal" }}><h4><b>Invoice info</b></h4></li>          
          <li key={i+ "Paid-Status"}>Paid Status: {item.PaidStatus==1?'Paid':'Unpaid'}</li>
          <li key={i+ "Date-Paid"}>Date Paid: {item.DatePaid?item.DatePaid:'N/A'}</li>         
           <li key={i+ "Refund-Status"}>Refund Status: {item.RefundStatus==1?'Refunded':'N/A'}</li>
           <li key={i+ "Date-Refund"}>Date Refund: {item.DateRefund?item.DateRefund:'N/A'}</li>
          <p></p>         
          <li>**********************************************</li>
        </ul>
      </div>
       </Col>
       </Row>
    </div>   
  );
}) ) : (
  <p>No more results found.</p>)  
  } 
     
</div> 
       
)  
         
}
export default MarveltechIn;