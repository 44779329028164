import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Catering from "./clientappCatering/Catering";
import HairAndBeauty from "./clientappHairtechnicians/HairAndBeauty";
import Merchandise from "./clientappMerchandise/Merchandise";
import DIYTechniciansTechnician from "./clientappDIYTechnicians/DIYTechnicians";
import HomeDashboard from"./HomeDashboard";
import MainBodyHairTechnician from "./clientappHairtechnicians/MainBodyHairTechnician";
import MainBodyDIY from "./clientappDIYTechnicians/MainBodyDIY";
import MainBodyMerchandise from "./clientappMerchandise/MainBodyMerchandise";
import MainBodyCatering from "./clientappCatering/MainBodyCatering";
import MainBodyInvoices from "./invoices/MainBodyInvoices";
import HomeConnections from "./clientappConnections/HomeConnections";
import Disclaimer from "./footer/disclaimer";
import Help from "./footer/help";
import Follow from "./footer/followus";
import Join from "./footer/joinus";
import Ask from "./footer/askus";
import Contact from  "./footer/askus";
import MainBodyConnections from "./clientappConnections/MainBodyConnections";
import AdminBody from "./admin/AdminDashBoardBody";
import Buyers from "./buyers/Buyer";
import BuyerBody from "./buyers/MainBodyBuyer";
import Sellers from "./clientAppSellers/Seller";
import SellerBody from "./clientAppSellers/MainBodySeller";

class Main extends Component {

  constructor(props) {
    super(props); // ✅ Always call super(props) first
    this.state = {
      businesses: props.businesses, // ✅ Store prop in state (optional)
    };
  }

  render() {
    return (
      <div>
        <SellerBody/> 
        <BuyerBody/> 
        <AdminBody/>    
        <MainBodyHairTechnician/>
        <MainBodyDIY/>
        <MainBodyMerchandise business ={this.state.businesses}/>
        <MainBodyCatering/>
        <MainBodyConnections/>
        <MainBodyInvoices/>
        <HashRouter>
          <Route exact path="/" component={HomeDashboard} />
          <Route exact path="/home" component={HomeDashboard} />
          <Route path="/homedashboard" component={HomeDashboard} />  
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />          
          <Route path="/followus" component={Follow} />
          <Route path="/contact" component={Ask} />
          <Route path="/joinus" component={Join} />
          <Route path="/askus" component={Ask} />
          <Route path="/hairandbeauty" component={HairAndBeauty} />
          <Route path="/catering" component={Catering} /> 
          <Route path="/merchandise" component={Merchandise} />
          <Route path="/DIYTechnicians" component={DIYTechniciansTechnician} /> 
          <Route path="/connections" component={HomeConnections} /> 
          <Route path="/buyerservice" component={Buyers} /> 
          <Route path="/clientAppSellerservice" component={Sellers} /> 
         <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
