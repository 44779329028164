
import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from './InvoiceDataTable';
import LocalStorageService from '../../../services/localStorageService';
import GetUrl from "../../services/urlServiceDIYTechnicians";
import CurrencyFormat from 'react-currency-format';
//class InvoicePage extends Component {
  const InvoiceByNumberPage = ({ invoiceNumber })  => { 

  const [invoiceNumberOrId, setInvoiceNumberOrId] = useState('');
  const [invoices, setInvoices] = useState('');
  const [invoices2, setInvoices2] = useState('');
  const [results, setResult] = useState([]);
  const [item1Name, setItem1Name] = useState([]);
  const [item1Price, setItem1Price] = useState([]);
  const [item1Qty, setItem1Qty] = useState([]);
  const [item2Name, setItem2Name] = useState([]);
  const [item2Price, setItem2Price] = useState([]);
  const [item2Qty, setItem2Qty] = useState([]);
  const [item3Name, setItem3Name] = useState([]);
  const [item3Price, setItem3Price] = useState([]);
  const [item3Qty, setItem3Qty] = useState([]);
  const [item4Name, setItem4Name] = useState([]);
  const [item4Price, setItem4Price] = useState([]);
  const [item4Qty, setItem4Qty] = useState([]);
  const [item5Name, setItem5Name] = useState([]);
  const [item5Price, setItem5Price] = useState([]);
  const [item5Qty, setItem5Qty] = useState([]);
  const [item6Name, setItem6Name] = useState([]);
  const [item6Price, setItem6Price] = useState([]);
  const [item6Qty, setItem6Qty] = useState([]);

  const getData = async () => {
    const API_PATH = getApiPath();
    
    // Determine which invoice number to use
    const invoiceToFetch = invoiceNumber?.trim() || invoiceNumberOrId;
  
    if (!invoiceToFetch) {
      console.warn("No valid invoice number provided.");
      return;
    }
  
    try {
      const { data } = await axios.post(API_PATH, { invoiceNumber: invoiceToFetch });
      
      setResult(data);      
      console.log("result:", data);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };
  

  useEffect(() => {
    getData();
    }, [invoices])

// useEffect (() =>{
//   retrieveInvoiceByNumber(); 
// }, []);

useEffect (() =>{
  setInvoiceNumberOrId(LocalStorageService("get", "invoiceNumber"))
}, );

useEffect(() => {
  getData();
}, [invoiceNumber]); // Fetch data when invoiceNumber changes


 function getToken (){
  return LocalStorageService("get", "token"); 
}; 

const  getApiPath = () =>  {   
  return GetUrl("retrieveInvoiceByNumberassembling");
};


useEffect(() => {
  retrieveInvoiceByNumber();
}, []); // This will run only once when the component is mounted

const retrieveInvoiceByNumber = () =>{
const API_PATH = getApiPath(); 
const body = {
  invoiceNumber: invoiceNumberOrId
};
if( getToken()) {
axios({
  method: "post",     
  url: `${API_PATH}`,
  data: body,
})
.then(result => {
    if (result.status === 200) {  
      const inv = result?.data;
      setInvoices(inv);    
        console.log("invoices in dom: ", invoices);
      }
    })
    .catch(function (error) {    
      console.log(error);
    });  
       
    }
  };
 
  
    const hasValidItems = (item) => {
      return Array.from({ length: 6 }).some((_, index) => {
        const qty = item[`Item${index + 1}Qty`];
        const price = item[`Item${index + 1}Price`];
        return qty > 0 && price > 0;
      });
    };
    
    return (
      <div className="content-invoice">
        {results.filter(hasValidItems).map((item, i) => (
          <Row key={i+""} className="justify-content-center">
            <Col md={{ span: 12, offset: 2 }}>
              <div className="invoice-wrapper-final">
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {/* Invoice Header */}
                  <li>
                    <div className="invoice-header">
                      <p>Invoice ID: {item.Id}</p>
                      <p>Invoice Number: {item.InvoiceNumber}</p>
                      <p>Invoice Status: {item.InvoiceStatus}</p>
                    </div>
                  </li>
    
                  {/* Created At */}
                  <li>Created At: {item.created_at}</li>
    
                  {/* Company Info */}
                  <li style={{ color: "purple" }}>
                    <h4><b>Company Info</b></h4>
                  </li>
                  <li>Your Service Name: {item.CatererCompanyName}</li>
                  <li>
                    Company Number: {item.CatererCompanyName?.toLowerCase().includes("marveltech") 
                      ? "26357/GTCA/RC/2022" 
                      : item.CatererCompanyNumber}
                  </li>
                 <p></p>
                  {/* Purchase Info */}
                  <li style={{ color: "purple" }}>
                    <h4><b>Purchase Info</b></h4>
                  </li>
                  <li>Product Name: {item.DishName}</li>
                  <li>Customer Phone: {item.CustomerPhoneNumber}</li>
    
                  {/* Dynamic Item List */}
                  {Array.from({ length: 6 }).map((_, index) => {
                    const name = item[`Item${index + 1}Name`];
                    const price = item[`Item${index + 1}Price`];
                    const qty = item[`Item${index + 1}Qty`];
    
                    return name && price > 0 && qty > 0 ? (
                      <React.Fragment key={`${i}-item${index + 1}`}>
                        <li>Item {index + 1} Name: {name}</li>
                        <li>Item {index + 1} Price: £{price}</li>
                        <li>Item {index + 1} Qty: {qty}</li>
                      </React.Fragment>
                    ) : null;
                  })}
                  <p></p>
                  <li style={{ color: "black" }}>
                               <h4><b>Summary</b></h4>
                             </li>
             <li key={i+"-Total"}>Total: £{item.total}</li>
             <li key={i+"-VAT"}>VAT: £{item.VAT}</li>
             <li key={i+"_Grand_Total"}>Grand_total: £{item.Grand_total}</li>
              <p></p>
              <p></p>
           
             <li style={{color:"green"}}><h4><b>Invoice info</b></h4></li>
             <li key={i+""}>Paid Status: {item.PaidStatus==1?'Paid':'Unpaid'}</li>
             <li key={i+""}>Date Paid: {item.DatePaid?item.DatePaid:'N/A'}</li>         
              <li key={i+""}>Refund Status: {item.RefundStatus==1?'Refunded':'N/A'}</li>
              <li key={i+""}>Date Refund: {item.DateRefund?item.DateRefund:'N/A'}</li>
             <p></p>         
             <li>*********************************************************</li>
                </ul>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    );  
   
    }
  export default InvoiceByNumberPage;