import React, { Suspense,  useState, useEffect, createContext } from "react";
import i18n from "./services/i18n";
import { withTranslation } from "react-i18next";
import Main from "./mainbody";
import Footer from "./footer/Footer";
import "./mainApp.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Stylesheets/StyleSheet.css";
import NavBar from "./responsive/Navbar";
import LocalStorageService from "./services/localStorageService";
import { faL } from "@fortawesome/free-solid-svg-icons";

function App({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
 
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [hasServiceCategory, setHasServiceCategory] = useState(false);
    
const [time, setTime] = useState(Date.now());

useEffect(() => {
  const interval = setInterval(() => setTime(Date.now()), 300);
  return () => {
    clearInterval(interval);
  };
}, []);

// useEffect(() => {
//   // Check if the current URL is https://londonrestaurants.info
//   if (window.location.hostname === 'https://londonrestaurants.info') {
//     // Redirect to https://manblaysaviours.com/#/catering
//     window.location.replace('https://manblaysaviours.com/#/catering');
//   }
// }, []);

  var servicecategory = LocalStorageService("get","servicecategory") ;

  return (
    <div>
    <div>
     <div className = "header-akwaba">      
      <NavBar />      
      </div>
      <div className = "">
      <Suspense fallback={<div>Loading</div>}>   
      <Main />     
      </Suspense>
      </div>
    </div>    
    <Footer />
    </div>
  );
}
export default withTranslation()(App);

